import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import djangoURL from '../GlobalVariables';
import { Button, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

const stripePromise = loadStripe('pk_live_51Q0b5IHcvnqFlwNgJRKhd4UsApprpBViltj80N7zp4kHhxf9ItwNF3hN41GvIK5NEtaBkpMTVRIy9SMpBFSrJ77800Fh2RWnJJ');

const PaymentForm = ({ courseId }) => {
    const stripe = useStripe();
    const elements = useElements();
    const navigate = useNavigate();
    const [clientSecret, setClientSecret] = useState('');
    const [loading, setLoading] = useState(false);
    const token = localStorage.getItem('token');

    if (!token) {
        return (
            <div>
                <Button sx={{ width: '100%' }} variant="contained" component={Link} to="/login">Login To Pay</Button>
                <Typography>OR</Typography>
                <Button sx={{ width: '100%' }} variant="contained" component={Link} to="/register">Register To Pay</Button>
            </div>
        );
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!stripe || !elements) return;

        setLoading(true);
        const cardElement = elements.getElement(CardElement);

        try {
            const response = await fetch(`${djangoURL}/Academy/api/create-payment-document/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${token}`
                },
                body: JSON.stringify({ courseId }),
            });

            if (!response.ok) throw new Error('Failed to fetch client secret');
            const data = await response.json();

            if (!data.clientSecret || typeof data.clientSecret !== 'string') {
                console.error('Invalid client secret:', data);
                throw new Error('Invalid client secret format');
            }

            setClientSecret(data.clientSecret);

            const { error, paymentIntent } = await stripe.confirmCardPayment(data.clientSecret, {
                payment_method: { card: cardElement },
            });

            if (error) {
                console.error('[Payment Error]', error);
                alert('Payment failed. Please check your card details and try again.');
                return;
            }

            if (paymentIntent.status === 'succeeded') {
                if (data.myDocsID) {
                    console.log("PAYMENT ID");
                    console.log(paymentIntent.id);
            
                    try {
                        const paymentSuccess = await fetch(`${djangoURL}/Academy/api/documents/payment/${data.myDocsID}/${paymentIntent.id}`, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': `Token ${token}`,
                            },
                        });
            
                        // Check if the response was successful
                        if (paymentSuccess.ok) {
                            // Parse the JSON response if needed
                            const responseData = await paymentSuccess.json();
                            console.log(responseData);  // Debugging: log the response
            
                            // Assuming the response body contains a status field
                            if (responseData.response === 'SUCCESS') {
                                navigate(`/MyDocuments/${data.myDocsID}`);
                            } else {
                                alert('An error occurred: ' + responseData.message || 'Contact System Administrator');
                            }
                        } else {
                            alert('Payment failed: ' + paymentSuccess.statusText);
                        }
                    } catch (error) {
                        console.error('Error during payment processing:', error);
                        alert('An error occurred. Please try again.');
                    }
                } else {
                    console.error('Missing myDocsID in response:', data);
                    alert('Payment succeeded, but an error occurred while processing your order.');
                }
            }
        } catch (error) {
            console.error('Error during payment confirmation:', error);
            alert('An error occurred. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <form onSubmit={handleSubmit} className="payment-form">
            <CardElement className="card-element" />
            <button type="submit" disabled={!stripe || loading} className="pay-button">
                {loading ? <div className="spinner"></div> : 'Pay'}
            </button>
        </form>
    );
};

const StripeContainer = ({ courseId }) => (
    <Elements stripe={stripePromise}>
        <PaymentForm courseId={courseId} />
    </Elements>
);

export default StripeContainer;