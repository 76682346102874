import React, { useState, useEffect } from 'react';
import AxiosInstance from './Axios'; // Import your Axios instance
import { useParams } from 'react-router-dom';
import { Link, Navigate  } from 'react-router-dom';
import { Container, Typography, Card, CardContent, Grid, CardMedia, Box, Button } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import ArticleIcon from '@mui/icons-material/Article';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import StripeContainer from './forms/PaymentForm';
import { styled } from '@mui/system';

import djangoURL from './GlobalVariables';

const CourseDetails = () => {
  const { id } = useParams();
  const [course, setCourse] = useState(null);
  const [loading, setLoading] = useState(true);
  const isLoggedIn = localStorage.getItem('token') !== null;
  console.log(isLoggedIn)
  const Container = styled('div')({
    '@media (min-width: 1200px)': {
      maxWidth: '100%',
    },
  });

  const features = [
    { icon: <VideoLibraryIcon sx={{color: '#fcad03'}} />, text: "Lecture Videos" },
    { icon: <ArticleIcon sx={{color: '#fcad03'}} />, text: "Relatetable Lectures" },
    { icon: <CloudDownloadIcon sx={{color: '#fcad03'}} />, text: "Downloadable resources" },
    { icon: <AllInclusiveIcon sx={{color: '#fcad03'}} />, text: "Full lifetime access" },
    { icon: <EmojiEventsIcon sx={{color: '#fcad03'}} />, text: "Certificate of completion" },
  ];

  useEffect(() => {
    const fetchCourseDetails = async () => {

      const token = localStorage.getItem('token'); // Retrieve the token
      if (!token) {
        console.error('No token found');
        try {
          const response = await AxiosInstance.get(`/Academy/api/course/${id}/notAuthenticated/`, {
            headers: {
              Authorization: `Token ${token}` // Attach the token in the Authorization header
            }
          });
          setCourse(response.data);
          setLoading(false);
        } catch (error) {
          console.error('Error fetching course details:', error);
          setLoading(false);
        }
      }else{
      try {
        const response = await AxiosInstance.get(`/Academy/api/course/${id}/`, {
          headers: {
            Authorization: `Token ${token}` // Attach the token in the Authorization header
          }
        });
        setCourse(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching course details:', error);
        setLoading(false);
      }
      }
    };

    fetchCourseDetails();
  }, [id]); // Depend on id since it's used in the URL

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <Container sx={{ padding: 5 }}>
      <Grid container spacing={5}>
        <Grid item xs={12} md={8}>
          <Card sx={{ backgroundColor: '#f1f7ff', marginBottom: 3 }}>
            <CardContent>
              <Grid container>
                <Grid item xs={12} sm={6}>
                  <CardMedia
                    component="img"
                    alt="Image"
                    height="500"
                    image={djangoURL + course.imageURL}
                    title="Image Title"
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
                >
                  <Typography variant="h3" component="h2" sx={{ textAlign: 'center' }}>
                    {course.course_name}
                  </Typography>
                  <Typography variant="body1" component="p" sx={{ textAlign: 'center' }}>
                    {course.course_short_description}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          <Card sx={{marginBottom: 3 }}>
            <CardContent>
              <Typography variant="h5" sx={{textAlign:'left', fontWeight: 'bold', marginBottom:2}}>
                This course includes:
              </Typography>
              <Grid container spacing={2}>
                {features.map((feature, index) => (
                  <Grid item xs={6} key={index} style={{ display: 'flex', alignItems: 'center' }}>
                    {feature.icon}
                    <Typography variant="body1" style={{ marginLeft: '8px' }}>
                      {feature.text}
                    </Typography>
                  </Grid>
                ))}
              </Grid>
            </CardContent>
          </Card>

          <Card sx={{marginBottom: 3 }}>
            <CardContent>
              <Typography variant="h5" sx={{marginBottom: 1, textAlign:'left', fontWeight: 'bold'}}>Course Description:</Typography>
              <Typography sx={{textAlign:'left', fontSize: 18}}>
                {course.course_full_description}
              </Typography>
            </CardContent>
          </Card>
          <Typography variant="h5" sx={{marginBottom: 1, textAlign:'left', fontWeight: 'bold'}}>Course Details:</Typography>
          {course.lectures.map((lecture) => (
            <MuiAccordion key={lecture.id}>
              <MuiAccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{backgroundColor: '#f6f6f6'}}
              >
                <Typography variant="h6" sx={{color:'blue'}}>{lecture.lecture_name}</Typography>
              </MuiAccordionSummary>
              <MuiAccordionDetails>
                <Typography sx={{textAlign:'left', fontSize:19, paddingLeft: '2%'}}>{lecture.lecture_description}</Typography>
                <ul style={{ paddingLeft: '10%', marginTop: '10px' }}>
                  {lecture.sub_lecture.map((sub_lecture) => (
                    <li key={sub_lecture.id}>
                      <Typography sx={{textAlign:'left', fontSize:18, marginBottom:1}}>{sub_lecture.sub_lecture_title}</Typography>
                    </li>
                  ))}
                </ul>
              </MuiAccordionDetails>
            </MuiAccordion>
          ))}

          
        </Grid>

        <Grid item xs={12} md={3}>
          <Box sx={{ position: 'sticky', top: 100 }}>
          {course.isPurchased ? (
              !isLoggedIn ? (
                <Card sx={{ marginBottom: 3 }} className="card-element">
                  <CardContent>
                    <Typography variant="h6" sx={{ padding: 5, textAlign: 'center', fontWeight: 'bold' }}>
                      Please Login or Register to buy this course.
                    </Typography>
                    <Button sx={{ width: '100%' }} variant="contained" component={Link} to="/Login">
                      Login or Register
                    </Button>
                  </CardContent>
                </Card>
              ) : (
                <Card sx={{ marginBottom: 3 }} className="card-element">
                  <CardContent>
                    <Typography variant="h6" sx={{ padding: 5, textAlign: 'center', fontWeight: 'bold' }}>
                      You've already purchased this Course.
                    </Typography>
                    <Button sx={{ width: '100%' }} variant="contained" component={Link} to={`/MyAcademy/${id}`}>
                      Take this Course
                    </Button>
                  </CardContent>
                </Card>
              )
            ) : (
              <Card sx={{ marginBottom: 3 }} className="card-element">
                <CardContent>
                  <Typography variant="h6" sx={{ marginBottom: 3, textAlign: 'left', fontWeight: 'bold' }}>
                    {course.course_name}
                  </Typography>
                  <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 25 }}>
                    <Typography sx={{ fontSize: 20, fontWeight: 'bold' }}>Price: </Typography>
                    <Typography sx={{ fontSize: 20, fontWeight: 'bold' }}>${course.price}</Typography>
                  </div>
                  <StripeContainer courseId={id} />
                </CardContent>
              </Card>
            )}
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default CourseDetails;
